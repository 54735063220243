:root {
    --window-red: #e03c20;
    --window-blue: #034b92;
    --window-yellow: #ffda27;
    --window-text:000;    
}

.window{
    background: white;
    border: 1px solid black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Title bar */
.window-header{
    background: var(--window-red);
    color: white;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: move;
}

.window-controls{
    display: flex;
    gap: 5px;
}

.window-control {
    width: 20px;
    height: 20px;
    border: 1px solid var(--window-text);
    background: var(--window-yellow);
    cursor: pointer;
    display: grid;
    place-content: center;
}

window.control:hover{
    background: #e0c21f;
}

/* Menu Bar */
.menu-bar {
    background-color: #034b92;
    color: white;
    padding: 5px;
    display: flex;
    gap: 10px;
}
  
.menu-bar .menu-button {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 3px;
}
  
.menu-bar .menu-button:hover {
    background-color: #f0f0f0;
    color: #034b92;
}
  
.menu-bar span {
    cursor: pointer;
    padding: 2px 5px;
    border-radius: 3px;
}
  
.menu-bar span:active {
    background-color: #ffda27;
    color: black;
}
  
.address-bar {
    background-color: #034b92;
    color: white;
    padding: 5px;
    display: flex;
    align-items: center;
}
  
.address-bar span {
    margin-right: 5px;
}
  
.address-bar input {
    width: 100%;
    padding: 2px 5px;
    border: none;
    border-radius: 3px;
}

/* Content */
.window-body{
    flex-grow: 1;
    overflow: auto;
}

.tab-bar{
    display: flex;
    background: var(--window-blue);
    position: sticky;
    top: 0;    
}

.tab{
    padding: 8px 16px;
    cursor: pointer;
    color: white;
    border-bottom: 2px solid transparent;
}

.tab.active{
    background: white;
    color: var(--window-text);
    border-bottom: var(--window-text);
}

/*Rezise handle*/
.react-resizable-handle{
    position: absolute !important;
    width: 30px;
    height: 30px;
    bottom: -3px;
    right: -1px;
    cursor: nwse-resize;
    transform: none !important; 
    z-index: 1000;
}

.react-resizable{
    overflow: visible !important;
    position: relative !important;
}

.window{
    transition: none !important;
}

.window >*:not(.react-resizable-handle){
    padding: 0;
}

/*Utility classes*/
.no-padding{
    padding: 0 !important;
}
.no-margin{
    margin: 0 !important;
}